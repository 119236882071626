import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import forms_conf from '../_generated/ext_config/forms.json';

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
};

/** Override generated form values here */
const forms_conf_overrides: Override = {};

/** Create custom forms or override generated forms here */
const forms = {
  // example_test: new FormOptions({
  //   slug: 'profile',
  //   object_name: 'Example',
  //   display: 'horizontal-wide',
  //   create_string: 'Profile',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...f, ...forms };
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}

type Override = { [Property in keyof Partial<typeof forms_conf>]: Partial<FormOptions> };
