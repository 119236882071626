import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  actions: {
    tableSpec: {
      columns: [
        { header: '', value: 'icon', type: 'fa-icon', typeOption: 'fa-2x', class: 'col-1' },
        { header: 'Name', value: 'name' },
      ],
    },
  },
  reasons: {
    tableSpec: {
      columns: [
        { header: '', value: 'icon', type: 'fa-icon', typeOption: 'fa-2x', class: 'col-1' },
        { header: 'Name', value: 'name' },
      ],
    },
  },
  service_types: {
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name' },
        { header: 'Safety Patrol', value: 'safetyPatrol', type: 'boolean' },
      ],
    },
  },
  service_deliveries: {
    tableSpec: {
      columns: [
        { header: 'Staff', value: 'staff', type: 'usericon', typeOption: 'small' },
        { header: 'Suburb', value: 'suburb', type: 'objvalue', typeOption: 'string' },
        { header: 'Service', value: 'serviceType', type: 'objvalue', typeOption: 'string' },
        { header: 'Start', value: 'startTime', type: 'date', typeOption: 'short' },
        { header: 'End', value: 'endTime', type: 'time' },
        { header: '', value: 'endTime', type: 'timedifference', typeOption: 'startTime' },
      ],
    },
  },
  referrals: {
    tableSpec: {
      columns: [
        { header: 'Date', value: 'date', type: 'date', typeOption: 'shortDate' },
        { header: 'Direction', value: 'directionLabel' },
        { header: 'Mandatory Report', value: 'mandatoryReport', type: 'boolean' },
        { header: 'Updates', value: 'referralUpdatesCount' },
        { header: 'Status', value: 'statusLabel' },
      ],
    },
  },
  engagements: {
    tableSpec: {
      columns: [
        { header: 'Staff', value: 'staff', type: 'usericon', typeOption: 'small' },
        { header: 'Time', value: 'start', type: 'date', typeOption: 'short' },
        { header: 'Suburb', value: 'suburb', type: 'objvalue', typeOption: 'string' },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
